import React from 'react'
import PropTypes from 'prop-types'
import { TransitionGroup, Transition as ReactTransition } from 'react-transition-group'

// import { EASE } from '../utils/presets'
const EASE = `ease-in-out`

// The duration for each phase of the transition
// So the total duration will be _twice_ this
const timeout = 300;

const defaultStyle = {
  transform: `translateX(0)`,
  transition: `transform ${timeout}ms ${EASE}, opacity 0ms linear`,
}

const getStyles = (direction, currentId, isEvents) => {
  return {
    entering: {
      position: 'absolute',
      transform: isEvents && (direction === `right` ? `translateX(-100%)` : `translateX(100%)`),
      // transitionDelay: `transform ${timeout * 2}ms`
      opacity: 1
    },
    entered: {
      opacity: 1
    },
    exiting: {
      transform: isEvents && (direction === `right` ? `translateX(-100%)` : `translateX(100%)`),
      opacity: !isEvents && 0
    },
    exited: {
      opacity: 0
    }
  }
}

const Transition = ({ children, location, currentId, isEvents }) => {
  // the page entering and the page exiting need to move in the same direction
  // the pathname changes durning the transition and toggles the direction
  let direction = `left`
  if (location.pathname === `${__PATH_PREFIX__}/`) {
    direction = `right`
  }

/*
  http://reactcommunity.org/react-transition-group/transition#Transition-prop-onEnter
  onEntered, onEntering, etc. produces slightly better (?) but also buggy results
  const handleOnEnter = (node) => {
    console.log("handleOnEnter: ", node)
    if (currentId) {
      const anchorTarget = document.getElementById(currentId)
      console.log("handleOnEnter: ", currentId, anchorTarget)
      if (anchorTarget) anchorTarget.scrollIntoView()
    }
  }
*/
  return (
    <TransitionGroup>
      <ReactTransition
        key={location.pathname}
        // timeout={{ enter: 900, exit: 300 }}
        timeout={timeout}
      >
        {(status) => {
          return (
            <div
              style={{
                ...defaultStyle,
                // ...(isEvents && getStyles(direction)[status])
                ...getStyles(direction, currentId, isEvents)[status]
              }}
            >{children}</div>
        )}}
      </ReactTransition>
    </TransitionGroup>
  )
};

Transition.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired
}

export default Transition
