module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-Y51NN9FDX0"],"gtagConfig":{"optimize_id":"OPT_CONTAINER_ID","anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":false,"respectDNT":true,"exclude":["/admin/**"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Leefwerk","short_name":"Leefwerk","description":"PLANNING TO SAVE THE WORLD WITH PUNCHLINES. MEANWHILE TRANSLATING POLITICAL EXPERIMENTS INTO POETIC URGENCIES. UNNECESSARY CREATIONS AND COMPLEX DETOURS LEAD TO INCOMPREHENSIVE DEFINITIONS. MOST OF IT IS MOSTLY LEADING TO PUBLIC EVENTS OF ALL KIND. ALL WILL BE JUSTIFIED...","start_url":"/","background_color":"#000","theme_color":"#fff","display":"standalone","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f219bb95b866f88dbbbd65ea59c129be"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
