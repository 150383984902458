import smoothscroll from 'smoothscroll-polyfill'

// CustomEvent error
// https://stackoverflow.com/questions/26596123/internet-explorer-9-10-11-event-constructor-doesnt-work
(function () {
  if ( typeof window.CustomEvent === "function" ) return false //If not IE

  function CustomEvent ( event, params ) {
    params = params || { bubbles: false, cancelable: false, detail: undefined }
    var evt = document.createEvent( 'CustomEvent' )
    evt.initCustomEvent( event, params.bubbles, params.cancelable, params.detail )
    return evt
   }

  CustomEvent.prototype = window.Event.prototype

  window.CustomEvent = CustomEvent
})()

// the script will detect if the spec is natively supported and take action only when necessary

export const onClientEntry = async () => {
  smoothscroll.polyfill()
}


export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition
}) => {
  const isEvents = location.pathname === `${__PATH_PREFIX__}/` || location.pathname === `${__PATH_PREFIX__}/image/`
  if (!isEvents) window.scrollTo(0, 0)
  return false
}