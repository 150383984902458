import React, { useState, useRef, useEffect } from "react"
import { Link, navigate } from "gatsby"
import { css, Global } from '@emotion/react'
import styled from '@emotion/styled'
import { useMediaQuery } from 'react-responsive'
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import { useEvents } from "../hooks/useEvents"
import { useMerch } from "../hooks/useMerch"
import { useSiteMetadata } from "../hooks/useSiteMetadata"
import formatDates, { formatAnchor } from "../utils/formatDates"
import Transition from '../components/Transition'
import Scrollspy from '../components/Scrollspy'
import { MIN_DEFAULT_MQ } from "../utils/presets"


export const CurrentContext = React.createContext({
  id: "",
  setCurrentId: () => {},
  address: "",
  setCurrentAddress: () => {},
});

const Wrapper = styled(`div`)({
  display: `flex`,
  overflow: `hidden`
})

const Menu = styled(`nav`)({
  position: `fixed`,
  height: `100%`,
  zIndex: 9999,
  background: `#000`,
  minWidth: `100vw`,
  transform: `translateX(-100%)`,
  transition: `transform 300ms ease-in-out`,
  [`ul`]: {
    margin: `1rem 0`,
    padding: `0 1rem 0 1rem`,
  },
  [MIN_DEFAULT_MQ]: {
    transform: `unset`,
    minWidth: `18rem`,
    [`ul`]: {
      margin: `1rem 0`,
      paddingLeft: `2rem`,
    },
  },
}, ({ visible }) => ({
  transform: visible && `translateX(0)`,
}))

const MenuToggle = styled(`div`)({
  paddingBottom: `1rem`,
  [MIN_DEFAULT_MQ]: {
    display: `none`
  },
})

const Header = styled(`div`)({
  display: `flex`,
  justifyContent: `space-between`,
  padding: `1rem 1rem 0 1rem`,
  [MIN_DEFAULT_MQ]: {
    padding: `1rem 0 0 2rem`,
  }
})

const Subheader = styled(`div`)({
  position: `fixed`,
  top: `-4rem`,
  left: `0`,
  right: `0`,
  padding: `5rem 1rem 1rem 1rem`,
  [MIN_DEFAULT_MQ]: {
    paddingLeft: 0,
    paddingRight: `2rem`,
    left: `18rem`,
  },
  background: `#000`,
  zIndex: 9998,
  display: `flex`,
  justifyContent: `space-between`,
  transform: `translateX(0)`,
  transition: `transform 300ms ease-in-out`,
  whiteSpace: `nowrap`,
  [`nav`]: {
    position: `absolute`,
    top: `5rem`,
    right: `1rem`,
    [MIN_DEFAULT_MQ]: {
      right: `2rem`,
    }
    // paddingLeft: `1rem`
  }
}, ({ visible }) => ({
  transform: visible && `translateX(100%)`
}))

const Main = styled(`main`)({
  position: `relative`,
  transform: `translateX(0)`,
  transition: `transform 300ms ease-in-out`,
  paddingBottom: `30%`,
}, ({ visible }) => ({
  // transform: visible && `translateX(100%)`,
}))


const Section = styled(`section`)({
  padding: `76px 1rem 2rem 1rem`,
  width: `calc(100vw - 2rem)`,
  [MIN_DEFAULT_MQ]: {
    width: `calc(100vw - 18rem)`,
    marginLeft: `18rem`,
    paddingTop: `64px`,
    paddingLeft: `0`,
    paddingBottom: `2rem`
  },
})

function findEventAddress(id, events) {
  return events.find(({ anchor }) => id === anchor) || {}
}

function formatDataForNav(data) {
  return data.map(({ node }) => {
    const { displayDate } = formatDates(node.startDate, node.endDate, node.yearOnly)
    return {
      ...node, // @TODO: lets pass what we need instead of destructing the entire node
      anchor: formatAnchor(node.startDate),
      date: displayDate
    }
  })
}

const Layout = ({ location, children }) => {
  const eventsSpy = useRef()
  const merchSpy = useRef()
  const [menuState, setMenuState] = useState(false)
  const [currentId, setCurrentId] = useState("")
  const isEvents = location.pathname === `${__PATH_PREFIX__}/` || location.pathname === `${__PATH_PREFIX__}/image/`
  const isMerch = location.pathname === `${__PATH_PREFIX__}/book-vinyl/` || location.pathname === `${__PATH_PREFIX__}/book-vinyl/image/`

  const [currentAddress, setCurrentAddress] = useState({address: "", link: "", translation: ""})
  const { title } = useSiteMetadata()
  const events = useEvents()
  const navItems = formatDataForNav(events)
  const merch = useMerch()
  const navItemsMerch = formatDataForNav(merch)

  // MQs
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" })

  useEffect(() => {
    if (isEvents, isMerch) {
      eventsSpy.current.getElements()
      merchSpy.current.getElements()
    }
  }, [isEvents, isMerch])

  useEffect(() => {
    if (isEvents || isMerch) {
      const anchorTarget = document.getElementById(currentId)
      if (anchorTarget) setTimeout(() => {
        anchorTarget.scrollIntoView({ behavior: "smooth", block: "start" } )
      }, 1000)

      eventsSpy.current.getElements()
      merchSpy.current.getElements()
    }
  }, [location.pathname])


  // same as below but with `isMerch` check (or `isMerch`)
  const handleMerchMenuClick = (event) => {
    handleMenuToggle()
    event.preventDefault();
    eventsSpy.current.removeActiveClass();
    if (isMerch) {
      const anchorTarget = document.getElementById(event.target.hash.replace("#", ""))
      if (anchorTarget) anchorTarget.scrollIntoView({ behavior: "smooth", block: "start" });
    } else {
      setCurrentId(event.target.hash.replace("#", ""))
      navigate(location.pathname === `${__PATH_PREFIX__}/book-vinyl/image/` ? `/image/` : `/book-vinyl/`)
    }
  }

  const handleEventMenuClick = (event) => {
    handleMenuToggle()
    event.preventDefault();
    merchSpy.current.removeActiveClass();
    if (isEvents) {
      const anchorTarget = document.getElementById(event.target.hash.replace("#", ""))
      if (anchorTarget) anchorTarget.scrollIntoView({ behavior: "smooth", block: "start" });
    } else {
      setCurrentId(event.target.hash.replace("#", ""))
      navigate(location.pathname === `${__PATH_PREFIX__}/image/` ? `/image/` : `/`)
    }
  }

  const handlePageLink = () => {
    // setIsEvents(false)
    eventsSpy.current.removeActiveClass()
    merchSpy.current.removeActiveClass()
    handleMenuToggle()
    setCurrentId("")
    setCurrentAddress({})
  }

  const handleMenuToggle = () => {
    if (isTabletOrMobile) setMenuState(!menuState)
  }

  const handleActiveChange = (id) => {
    if (isEvents) {
      setCurrentId(id)
      const { address, addressLink, translation = ""} = findEventAddress(id, navItems)
      setCurrentAddress({address, addressLink, translation})
    }
    if (isMerch) {
      setCurrentId(id)
      setCurrentAddress({})
    }
  }


  const navItemPath = !isEvents && !isMerch ? `/` : ``

  const getTitle = () => currentAddress.translation || title

  return (
    <React.Fragment>
      <GatsbySeo
        title='BE32733059332102'
        titleTemplate='LEEFWERK - %s'
        description='PLANNING TO SAVE THE WORLD WITH PUNCHLINES. MEANWHILE TRANSLATING POLITICAL EXPERIMENTS INTO POETIC URGENCIES. UNNECESSARY CREATIONS AND COMPLEX DETOURS LEAD TO INCOMPREHENSIVE DEFINITIONS. MOST OF IT IS MOSTLY LEADING TO PUBLIC EVENTS OF ALL KIND. ALL WILL BE JUSTIFIED...'
        openGraph={{
          type: "website",
          locale: "en_GB",
          url: "https://www.leefwerk.org",
          site_name: "LEEFWERK",
          images: [
            {
              url: "https://www.leefwerk.org/images/BE32733059332102.png",
              width: 1200,
              height: 630,
              alt: "LEEFWERK – BE32 7330 5933 2102",
            },
          ],
        }}
      />
      <Global
        styles={css`
          html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video{margin:0;padding:0;border:0;font-size:100%;font:inherit;vertical-align:baseline}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}body{line-height:1}ol,ul{list-style:none}blockquote,q{quotes:none}blockquote:before,blockquote:after,q:before,q:after{content:'';content:none}table{border-collapse:collapse;border-spacing:0}
          html {
            font-size: 14px;
            line-height: 18px;
            text-transform: uppercase;
            color: #FFF;
            background: #000;
            -webkit-text-size-adjust: 100%;
          }
          body {
            font-size: 14px;
            font-family: Arial, sans-serif;
            font-weight: normal;
            letter-spacing: 0.15px;
            line-height: 18px;
          }
          h1,h2,h3,h5,h6 {
            font-size: 1rem;
            line-height: 18px;
          }
          a {
            color: #FFF;
            text-decoration: none;
          }
          a:hover {
            text-underline-offset: 0.1rem;
            text-decoration-line: underline;
          }
          video {
            outline: none;
          }
          .current {
            text-underline-offset: 0.1rem;
            text-decoration-line: underline;
          }
        `}
      />
      <CurrentContext.Provider value={{ id: currentId, ...currentAddress, setCurrentId, setCurrentAddress }}>
        <Wrapper>
          <Menu visible={menuState}>
            <Header>
              <hgroup>
                <h1>
                  <Link to={`/about/`} activeClassName="current" onClick={handlePageLink}>{getTitle()}</Link>
                </h1>
                <p>BE32 7330 5933 2102</p>
              </hgroup>
              <MenuToggle onClick={handleMenuToggle}>{`Close`}</MenuToggle>
            </Header>
            <Scrollspy
              ref={eventsSpy}
              items={navItems.map(({ anchor }) => anchor)}
              activeClass={isEvents ? "current" : ""}
              options={{
                rootMargin: '-20% 0px -80%',
                threshold: 0,
              }}
              handleActiveChange={handleActiveChange}
            >
              <ul>
                {navItems.map(({ anchor, date }) =>
                  <li key={anchor}><Link to={`${navItemPath}#${anchor}`} onClick={handleEventMenuClick}>{date}</Link></li>
                )}
              </ul>
            </Scrollspy>
            <ul>
              <li>Book / Vinyl</li>
            </ul>
            <Scrollspy
              ref={merchSpy}
              items={navItemsMerch.map(({ anchor }) => anchor)}
              activeClass={isMerch ? "current" : ""}
              options={{
                rootMargin: '-20% 0px -80%',
                threshold: 0,
              }}
              handleActiveChange={handleActiveChange}
            >
              <ul>
                {navItemsMerch.map(({ anchor, date }) =>
                  <li key={anchor}><Link to={`${navItemPath}#${anchor}`} onClick={handleMerchMenuClick}>{date}</Link></li>
                )}
              </ul>
            </Scrollspy>
            <footer>
              <ul>
                <li><Link to="/contact/" activeClassName="current" onClick={handlePageLink}>Contact</Link></li>
                <li><Link to="/support/" activeClassName="current" onClick={handlePageLink}>Support</Link></li>
                <li><Link to="/subscribe/" activeClassName="current" onClick={handlePageLink}>Subscribe</Link></li>
              </ul>
            </footer>
          </Menu>
          <Subheader
            // visible={menuState}
          >
            <div>
              <MenuToggle onClick={handleMenuToggle}>{`Menu`}</MenuToggle>
              <a href={currentAddress.addressLink} target="_blank">{currentAddress.address}</a>
            </div>
            <nav>
              { isEvents && <React.Fragment>
                <Link to={`/`} activeClassName="current">{`Text`}</Link> / <Link activeClassName="current" to={`/image/`}>{`Image`}</Link>
                </React.Fragment>
              }
              { isMerch && <React.Fragment>
                <Link to={`/book-vinyl/`} activeClassName="current">{`Text`}</Link> / <Link activeClassName="current" to={`/book-vinyl/image/`}>{`Image`}</Link>
                </React.Fragment>
              }
            </nav>
          </Subheader>
          <Main
            // visible={menuState}
          >
            <Transition location={location} isEvents={isEvents || isMerch}>
              <Section>{children}</Section>
            </Transition>
          </Main>
        </Wrapper>
      </CurrentContext.Provider>
    </React.Fragment>
  )
}

export default Layout
