const { format } = require(`date-fns`)

// function isValidDate(date) {
//   return date && Object.prototype.toString.call(date) === "[object Date]" && !isNaN(date);
// }

const formatDates = (start, end, yearOnly) => {
  const pattern = yearOnly ? `yyyy` : `dd.MM.yyyy`
  const displayStart = typeof start === `string` && start.length > 0 && format(new Date(start), pattern)
  const displayEnd = typeof end === `string` && end.length > 0 && format(new Date(end), pattern)
  let displayDate
  if (displayStart && displayEnd) {
    displayDate = `${displayStart} – ${displayEnd}`
  } else {
    displayDate = displayStart
  }
  return {
    displayStart,
    displayEnd,
    displayDate
  }
}

export const formatAnchor = (start) => {
  return typeof start === `string` && start.length > 0 && format(new Date(start), `ddMMyyyy`)
}

export default formatDates
