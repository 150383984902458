// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-book-vinyl-image-js": () => import("./../../../src/pages/book-vinyl/image.js" /* webpackChunkName: "component---src-pages-book-vinyl-image-js" */),
  "component---src-pages-book-vinyl-js": () => import("./../../../src/pages/book-vinyl.js" /* webpackChunkName: "component---src-pages-book-vinyl-js" */),
  "component---src-pages-image-js": () => import("./../../../src/pages/image.js" /* webpackChunkName: "component---src-pages-image-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-subscribe-js": () => import("./../../../src/pages/subscribe.js" /* webpackChunkName: "component---src-pages-subscribe-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-templates-event-js": () => import("./../../../src/templates/event.js" /* webpackChunkName: "component---src-templates-event-js" */),
  "component---src-templates-info-js": () => import("./../../../src/templates/info.js" /* webpackChunkName: "component---src-templates-info-js" */),
  "component---src-templates-merch-js": () => import("./../../../src/templates/merch.js" /* webpackChunkName: "component---src-templates-merch-js" */)
}

