import { useStaticQuery, graphql } from "gatsby"
export const useEvents = () => {
  const { allSanityEvent } = useStaticQuery(
    graphql`
      query EventsData {
        allSanityEvent(sort: { fields: [startDate], order: DESC }) {
          edges {
            node {
              slug {
                current
              }
              _rawBody(resolveReferences: {maxDepth: 10})
              startDate
              endDate
              eventLink
              translation
              address
              addressLink
              insta {
                id
                hashtags
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 1080
                      outputPixelDensities: [0.5, 1, 2]
                      quality: 100
                      placeholder: BLURRED
                      formats: [AUTO, WEBP]
                      transformOptions: {grayscale: true}
                    )
                  }
                }
                carouselImages {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        width: 1080
                        outputPixelDensities: [0.5, 1, 2]
                        quality: 100
                        placeholder: BLURRED
                        formats: [AUTO, WEBP]
                      )
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  return allSanityEvent.edges
}